@import "../../config/rtl";

.ScreenHomeVolunteer-container {
    padding: 20px 20px 50px;

    .content {
        margin: 0 auto;
        max-width: 1050px;
    }

    h2 {
        margin-top: 10px;
    }

    h3 {
        font-size: 20px;
        font-weight: 200;
        padding: 0;
        margin: 0;
    }

    p {
        line-height: 20px;
        max-width: 800px;
    }

    ul {
        line-height: 20px;

        li {
            margin-top: 4px;
        }
    }

    a {
        width: 200px;
    }

    strong {
        padding-#{$right}: 5px;
    }
}

@media (max-width: 1050px) {
    .ScreenHomeVolunteer-container {
        p {
            line-height: 22px;
        }

        ul {
            line-height: 22px;

            li {
                margin-top: 10px;
            }
        }
    }
}
