@import "../../config/rtl";

.BlogBox-container {
    background-color: black;
    margin-right: 1px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    img {
        max-width: 500px;
    }

    .image-link {
        flex: 1;

        .image {
            width: 100%;
            background-size: cover;
            background-position: center center;
            height: 100%;
        }
    }

    .details {
        flex: 1;
        .inner {
            padding: 25px 35px;

            a.title {
                font-style: normal;
                font-weight: 700;
                letter-spacing: 0.008em;
                line-height: 0.9;
                text-transform: uppercase;
                font-size: 4em;
                line-height: 1em;
                font-family: $font3;
                font-weight: 400;
                color: white;
                font-style: normal;

                &:hover {
                    color: palevioletred;
                    text-decoration: none;
                }
            }

            .author {
                margin-top: 0.4em;

                .by {
                    color: white;
                    margin-#{$right}: 5px;
                    text-transform: uppercase;
                }

                .authorName {
                    color: palevioletred;
                }
            }
        }
    }

    &.size-1 {
        flex-basis: calc(70% - 1px * 2);
        flex-direction: row;

        &.flip {
            flex-direction: row-reverse;
        }

        .image-link {
            flex: 1;

            .image {
                width: 100%;
            }
        }
    }

    &.size-2,
    &.size-3 {
        flex-basis: calc(30% - 1px * 2);

        .image-link {
            flex: 1;
            overflow: hidden;
            min-height: 235px;

            .image {
            }
        }

        .details {
            flex: 1;
            .inner {
                padding: 12px 22px 17px;

                a.title {
                    font-style: normal;
                    font-weight: 700;
                    letter-spacing: 0.008em;
                    line-height: 0.9;
                    text-transform: none;
                    font-size: 1.5em;
                    line-height: 1.2em;
                    font-family: $font2;
                    font-weight: 400;
                    color: white;
                    font-style: normal;

                    &:hover {
                        color: palevioletred;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &.size-3 {
        flex-basis: calc(33% - 1px * 2);
    }
}

@media (max-width: 1050px) {
    .BlogBox-container {
        padding: 8px;

        img {
            max-width: 100%;
        }

        margin-top: 1px;

        .image-link {
            flex: 1;
            min-height: 100px;

            .image {
                width: 100%;
                background-size: cover;
                background-position: center center;
                height: 100%;
            }
        }

        .details {
            flex: 1;
            .inner {
                padding: 5px 6px;

                a.title {
                    font-size: 1em;
                    font-family: $font2;
                    text-transform: lowercase;
                }

                .author {
                    font-size: 12px;

                    .by {
                        text-transform: none;
                    }
                }
            }
        }

        &.size-1 {
            .image-link {
                flex: 1;

                .image {
                }
            }

            .details {
                padding-#{$left}: 5px;
            }

            &.flip {
                flex-direction: row;
            }
        }

        &.size-2,
        &.size-3 {
            flex-basis: calc(30% - 1px * 2);
            flex-direction: row;

            .image-link {
                flex: 1;
                overflow: visible;
                min-height: 0;
                max-width: 100px;

                .image {
                    width: 100%;
                    height: 100%;
                }
            }

            .details {
                flex: 1;
                .inner {
                    padding: 7px;

                    a.title {
                        font-size: 1em;
                    }
                }
            }
        }

        &.size-3 {
            flex-basis: calc(33% - 1px * 2);
        }
    }
}
