@import "../../config/rtl";

.Search-container {
    .react-autosuggest__container {
        position: relative;
    }

    .react-autosuggest__input {
        width: 240px;
        padding: 10px 20px;
        font-family: Helvetica, sans-serif;
        font-size: 20px;

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
    }

    .react-autosuggest__input:focus {
        outline: none;
    }

    .react-autosuggest__container--open .react-autosuggest__input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container {
        display: none;
    }

    .react-autosuggest__container--open
        .react-autosuggest__suggestions-container {
        display: block;
        position: absolute;
        top: 51px;
        width: 280px;
        border: 1px solid #aaa;
        background-color: #fff;
        font-family: Helvetica, sans-serif;
        font-weight: 300;
        font-size: 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px 20px;
    }

    .react-autosuggest__suggestion:not(:first-child) {
        border-top: 1px solid #ddd;
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: papayawhip;
        color: #fff;
    }

    .react-autosuggest__suggestion--focused {
        background-color: #0c7eaf;
        color: #fff;
    }

    .react-autosuggest__suggestion--focused .highlight {
        color: #120000;
    }

    .suggestion-content {
        display: flex;
        align-items: center;
        background-repeat: no-repeat;
    }

    .highlight {
        color: #ee0000;
        font-weight: bold;
    }

    .name {
        color: #333;
        margin-#{$right}: 68px;
        line-height: 45px;
    }
}
