.Key-container {
    background-color: #ccc;
    color: #333;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: inset -5px -5px 3px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    user-select: none;
    pointer-events: all;

    &.simple {
        background-color: #111;
        color: #fff;
        border: none;
        outline: none;

        .title {
            display: none;
        }
    }

    &.fast {
        position: absolute;
        right: 0;
        top: 15px;
    }

    &.slow {
        position: absolute;
        right: 50px;
        top: 15px;
    }

    &.big {
        width: 80px;
        height: 80px;
        box-shadow: inset -9px -9px 6px 2px rgba(0, 0, 0, 0.1);
    }

    .title {
        font-size: 10px;
        color: gray;
        font-weight: 500;
        direction: ltr;
        position: absolute;
        bottom: 1px;
        right: 1px;
    }

    &:hover {
        cursor: pointer;
        box-shadow: inset -5px -5px 3px 1px rgba(0, 0, 0, 0.2);
    }

    &:active,
    &.mouseDown {
        box-shadow: inset 5px 5px 3px 1px rgba(0, 0, 0, 0.1);
        transform: translate(1px, 1px);
    }

    &.big {
        &:hover {
            box-shadow: inset -9px -9px 6px 2px rgba(0, 0, 0, 0.2);
        }

        &:active,
        &.mouseDown {
            box-shadow: inset 9px 9px 6px 2px rgba(0, 0, 0, 0.1);
            transform: translate(1px, 1px);
        }

        .title {
            font-size: 13px;
            color: gray;
            font-weight: 500;
            direction: ltr;
            position: absolute;
            bottom: 6px;
            right: 6px;
        }
    }
}

@media (max-width: 1050px) {
    .Key-container {
        &.simple {
            background-color: transparent;
        }
    }
}
