.VideoSpeed-container {
    position: absolute;
    top: -32px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 5px 10px;
    z-index: 999;
    font-size: 25px;
    border-radius: 3px;
    font-family: monospace;
    color: #ddd;
}
