.Wiki-container {
    a {
        color: dodgerblue;
    }

    p {
        line-height: 22px;
    }

    li {
        line-height: 24px;
    }

    h1 {
        font-weight: 500;
        font-size: 24px;
        font-weight: 500;
        padding-top: 10px;
    }

    h2 {
        font-weight: 400;
        border-bottom: 1px solid #aaa;
        display: inline-block;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    h3 {
        font-weight: bold;
        font-size: 18px;
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    p {
        margin: 5px 0;
    }
}

body.staging {
    a {
        color: cyan;
    }
}

@media (max-width: 1050px) {
    .Wiki-container {
        h1 {
        }

        &.gold {
            h1 {
                color: gold;
            }
        }
    }
}
