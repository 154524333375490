.ProjectTable-container {
    width: 300px;

    table {
        width: 100%;
        background-color: #f9f9f9;
        border: 1px solid #aaaaaa;
        font-size: 14px;
        border-radius: 5px;

        tr {
            th {
                padding: 10px;
                background-color: #cedaf2;
                color: #333;
            }

            td {
                padding: 5px;
                border-top: 1px solid #ddd;

                &:first-child {
                    font-weight: 500;
                    width: 40%;
                }
            }
        }
    }
}

body.staging {
    .ProjectTable-container {
        table {
            background-color: #333;
            border: 1px solid #555;

            tr {
                th {
                    background-color: #444;
                    color: #f4f4f4;
                }

                td {
                    border-top: 1px solid #555;
                    color: #f3f3f3;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .ProjectTable-container {
        margin-top: 20px;
        width: 100%;

        table {
            width: 100%;
            background-color: #f9f9f9;
            border: 1px solid #aaaaaa;
            font-size: 14px;
            border-radius: 5px;

            tr {
                th {
                    padding: 10px;
                    background-color: #cedaf2;
                    color: #333;
                }

                td {
                    padding: 5px;
                    border-top: 1px solid #ddd;

                    &:first-child {
                        font-weight: 500;
                        width: 40%;
                    }
                }
            }
        }
    }
}
