.Icon-container {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: inherit;
    text-decoration: none;
    margin: 0 auto;

    i {
        font-size: 20px;
    }

    &.circle {
        background-color: #ddd;
        width: 22px;
        height: 22px;
        color: #333;
        border-radius: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: auto;

        &.pink {
            background-color: white;

            i {
                color: palevioletred !important;
            }
        }

        &.green {
            background-color: green;
            color: white;
        }

        &.pink,
        &.green {
            &:hover {
                color: inherit;

                i {
                    color: inherit;
                }
            }
        }
    }

    &.material {
        i {
            font-size: 24px;
        }
    }

    &.spin {
        animation: Spin infinite 800ms linear;
    }

    &.link {
        cursor: pointer;

        &:hover {
            text-decoration: none;
            color: palevioletred;

            i {
                color: palevioletred;
            }
        }
    }

    &.disabled {
        opacity: 0.5;
        cursor: default;

        &:hover {
            color: inherit;

            i {
                color: inherit;
            }
        }
    }
}

@media (max-width: 1050px) {
    .Icon-container {
        width: 30px;
        height: 30px;
        border-radius: 15px;

        i {
            font-size: 22px;
        }

        &.material {
            i {
                font-size: 26px;
            }
        }
    }
}

@keyframes Spin {
    from {
        transform: rotate(0deg);
        transform-origin: center;
    }
    to {
        transform: rotate(360deg);
        transform-origin: center;
    }
}
