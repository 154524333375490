// RTL languages directions.
$left: left;
$right: right;

$ltr: ltr;
$rtl: rtl;

$font: BlinkMacSystemFont, "Segoe UI", "Roboto",
"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
"Helvetica Neue", sans-serif;
$font2: BlinkMacSystemFont, "Segoe UI", "Roboto",
"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
"Helvetica Neue", sans-serif;
$font3: balboa-extra-condensed, sans-serif;
