.Loader-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    img {
        animation: Spin infinite 800ms linear;
    }
}

@keyframes Spin {
    from {
        transform: rotate(0deg);
        transform-origin: center;
    }
    to {
        transform: rotate(360deg);
        transform-origin: center;
    }
}
