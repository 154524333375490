@import "../../config/rtl";

.BlogFeatured-container {
    width: 300px;
    margin-top: 30px;
    margin-left: 30px;
    align-self: flex-start;

    h2 {
        text-align: center;
        color: palevioletred;
        font-family: $font3;
        font-size: 2.2em;
        font-weight: 200;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        padding: 0;
        margin: 0;
        margin-bottom: 0.3em;
    }

    .items {
        .item {
            text-decoration: none;
            margin-bottom: 1em;

            .image {
                position: relative;
                width: 280px;
                height: 160px;
                background-size: cover;
                background-position: center center;

                .play {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 40px;
                    height: 40px;
                    margin-#{$left}: -20px;
                    margin-top: -20px;
                    background-color: rgba(255, 255, 255, 0.8);
                    border-radius: 20px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
            }

            .title {
                color: #333;
                font-size: 0.9em;
                margin-top: 8px;
                line-height: 1.1em;
            }
        }
    }
}
