.BlogVideo-container {
    margin-top: 30px;
    position: relative;

    .delete {
        position: absolute;
        left: -40px;
        top: 20px;
    }
}
