@import "../../config/rtl";

.BlogTopHeader-container {
    background-color: #000;
    background: radial-gradient(lighten(#000, 10%), #000);
    background-size: cover;
    height: 290px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
        font-size: 160px;
        font-weight: bold;
        color: white;
        font-family: "Share Tech Mono", monospace;
    }

    .date {
        font-weight: 500;
        letter-spacing: 0.05em;
        text-align: center;
        transition: color 0.1s, background-color 0.1s, fill 0.1s;
        color: white;
        font-family: $font;
    }
}

@media (max-width: 1050px) {
    .BlogTopHeader-container {
        display: none;
    }
}
