@import "../../config/rtl";

.Content-container {
    flex: 1;
    margin-#{$right}: 40px;
}

@media (max-width: 1000px) {
    .Content-container {
        margin-#{$right}: 0;
    }
}
