.Tag-container {
  background-color: green;
  margin: 10px;
  padding: 5px 10px;
  border-radius: 3px;
  color: white;

  &.red {
  }
}
