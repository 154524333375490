.Blog-container {
    background-color: white;
    flex: 1;
    background-color: black;
    color: black;

    .lists {
        > .row {
            align-items: flex-start;
        }
    }

    .content {
        margin: auto;
        margin-top: 20px;
        max-width: 1300px;
    }

    .saving {
        height: 25px;
        color: brown;
        position: absolute;
        top: 105px;
    }

    .panel {
        position: absolute;
        transform: translateX(-180px) translateY(30px);
    }

    .lists {
        margin: auto;
        max-width: 1300px;
        background-color: white;
        padding: 10px 100px;
        box-sizing: border-box;
    }
}

@media (max-width: 1050px) {
    .Blog-container {
        background-color: white;
        flex: 1;
        background-color: black;
        color: black;

        > .content {
            margin: auto;
            margin-top: 20px;
            max-width: 100%;
            padding: 0 20px;
        }

        .lists {
            padding: 10px 0;
        }

        .lists {
            > .row {
                align-items: flex-start;

                .featured {
                    display: none;
                }
            }
        }
    }
}
