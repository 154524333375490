.Egg-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 50px;

    .country {
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .count {
            margin-top: 20px;
            font-size: 30px;
            text-align: center;
            background-color: #eee;
            border-radius: 5px;
            box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
            width: 150px;
            font-family: "Courier New", Courier, monospace;
        }
    }
}
