@import "../config/rtl";

html {
    min-height: 100%;
    display: flex;
    background-color: #eee;

    &.smooth {
        scroll-behavior: smooth;
    }
}

body {
    flex: 1;
    display: flex;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #e9ebee;
    direction: $ltr;
    color: #333;
    border-color: gray;

    &.no-scroll {
        overflow: hidden;
    }

    .env {
        display: none;
    }

    &.admin {
        .env {
            display: block;
        }
    }

    &.staging {
        background-color: #333;
        color: #eee;
        border-color: #666;

        .Breadcrumbs-container .crumb a {
            color: cyan;
        }
    }
}

#root {
    flex: 1;
    display: flex;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.desktop-panel {
    border-#{$left}: 2px solid gray;
    position: relative;
    background-color: #333;
    font-family: $font;
    overflow: auto;
    display: flex;
    position: fixed;
    #{$right}: 0;
    width: 50%;
    bottom: 0;
    top: 43px;
    overflow: auto;

    &.xrayEdit {
        background-color: darken(goldenrod, 40%);
    }
}

@media (max-width: 1050px) {
    .desktop-panel {
        display: none;
    }
}
