.App {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 42px;
}

@media (max-width: 1050px) {
    .App {
        padding-top: 42px;
    }
}
