.Avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-size: cover;
    background-position: center center;
}

@media (max-width: 1050px) {
    .Avatar-container {
        width: 30px;
        height: 30px;
        border-radius: 15px;
    }
}
