@import "../../config/rtl";

.ScreenRoot-container {
    flex: 1;

    .main {
        padding-#{$right}: 50%;

        .loader {
            margin: 50px auto;
        }
    }

    a {
        color: cyan;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .popoi {
        color: #fff;
        text-shadow: -1px 0 0 #aaa;
        font-size: 40px;
        position: absolute;
        left: -26px;
        top: 50%;
        margin-top: -30px;
        display: none;

        &.show {
            display: block;
        }
    }
}

body.staging {
    .panel {
        border-#{$left}: 2px solid #444;
    }
}

@media (max-width: 1050px) {
    .ScreenRoot-container {
        flex: 1;
        display: flex;

        .main {
            padding-#{$right}: 0;
            display: flex;
            flex: 1;
        }

        .popoi {
            display: none;

            &.show {
                display: none;
            }
        }
    }
}
