.blog {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: rgba(0, 0, 0, 0.84);

    h1,
    .h1 {
        font-weight: 400;
        font-size: 40px;
        line-height: 48px;
        margin: 0;
        margin-top: 0.78em;
        word-break: break-word;
    }

    h2,
    .h2 {
        margin-top: 1.72em;
        font-size: 24px;
        line-height: 1.18;
        margin-bottom: -0.31em;
        letter-spacing: -0.022em;
        font-weight: 600;
        font-style: normal;

        &.subtitle {
            margin-top: 0.74em;
            line-height: 32px;
            font-weight: 300;
            color: rgba(0, 0, 0, 0.54);
        }
    }

    p,
    .p {
        margin: 0;
        padding: 0;
        margin-top: 2em;
        font-size: 20px;
        line-height: 1.58;
        color: rgba(0, 0, 0, 0.84);
        font-style: normal;
        word-break: break-word;
        letter-spacing: -0.004em;
    }

    .caption {
        color: gray;
        font-size: 12px;
    }

    .quote {
        font-size: 26px;
    }

    a {
        color: dodgerblue;
    }
}

@media (max-width: 1050px) {
    .blog {
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        color: rgba(0, 0, 0, 0.84);

        h1,
        .h1 {
            font-size: 34px;
            line-height: 40px;
        }

        h2,
        .h2 {
            font-size: 20px;

            &.subtitle {
                font-weight: 400;
            }
        }

        p,
        .p {
            font-size: 18px;
            line-height: 1.58;
        }

        .caption {
            color: gray;
            font-size: 12px;
        }

        .quote {
            font-size: 20px;
            line-height: 24px;
        }
    }
}
