.ButtonWithLoader-container {
    button {
        width: 150px;
    }

    &.full {
        button {
            width: 100%;
        }
    }

    .loading:hover {
        background-color: white;
    }
}
