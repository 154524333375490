@import "../../config/rtl";

.LofCard-container {
    width: 300px;
    min-height: 200px;
    padding: 5px;
    box-sizing: border-box;
    position: relative;

    &:hover {
        .inner {
            cursor: pointer;
            border: 2px solid gold;
            color: palevioletred;
        }
    }

    .inner {
        position: relative;
        border: 2px solid #eee;
        background-color: #fff;
        box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.01),
            inset 0 0 0 2px rgba(0, 0, 0, 0.2);
        border-radius: 15px 30px;
        overflow: hidden;
        padding: 30px 20px 10px;
        box-sizing: border-box;
        position: relative;
    }

    .header {
        position: absolute;
        top: -1px;
        padding: 5px 0;
        border-radius: 15px;
        font-size: 14px;
        color: #666;
        #{$left}: 50%;
        margin-#{$left}: -60px;
        width: 120px;
        z-index: 999;
        text-align: center;
        box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.01);
    }

    .color-nvcs {
        background-color: dodgerblue;
        color: white;
    }

    .color-2 {
        background-color: #c4302b;
        color: white;
    }

    .color-lofs {
        background-color: palevioletred;
        color: white;
    }

    .color-4 {
        background-color: yellowgreen;
        color: white;
    }

    .color-5 {
        background-color: brown;
        color: gold;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .name {
            color: #365899;
            font-weight: 600;
            font-size: 14px;
            line-height: 1.38;
            margin-#{$right}: 30px;
        }

        .date {
            color: gray;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.38;
        }

        > .image {
            width: 40px;
            min-width: 40px;
            height: 40px;
            background-size: cover;
            border-radius: 20px;
            margin-#{$right}: 10px;
        }
    }

    .topic {
        font-weight: bold;
        text-align: center;
        padding: 10px 0 0;
    }

    .image {
        position: relative;

        .play {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            margin-#{$left}: -20px;
            margin-top: -30px;
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .preview {
        span {
            font-size: 30px;
        }

        font-size: 14px;
        position: relative;
        top: -6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #333;
    }

    .count {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        top: 0px;
        #{$right}: 0px;
        padding: 5px 10px;
        border-bottom-#{$left}-radius: 10px;

        i {
            font-size: 24px;
            margin-#{$right}: 5px;
        }

        span {
            font-size: 24px;
            padding-#{$right}: 3px;
        }
    }

    .stat {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: palevioletred;
        margin-top: 6px;

        i {
            font-size: 24px;
            margin-#{$right}: 5px;
        }

        span {
            font-size: 24px;
            padding-#{$left}: 3px;
        }

        &.small {
            color: #99a;
            font-size: 20px;

            span {
                font-size: 16px;
            }
        }
    }

    .stats {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

body.staging {
    .LofCard-container {
        width: 300px;
        min-height: 200px;
        padding: 5px;
        box-sizing: border-box;
        position: relative;

        &:hover {
            .inner {
                background-color: #252525;
                cursor: pointer;
                color: palevioletred;
            }
        }

        .inner {
            position: relative;
            border: 2px solid transparent;
            background-color: #222;
        }

        .row {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .name {
                color: palevioletred;
            }

            .date {
                color: #ccc;
            }

            .image {
                border: 1px solid #aaa;
            }
        }

        .preview {
            color: #ccc;
        }
    }
}

@media (max-width: 1050px) {
    .LofCard-container {
        width: 100%;
        margin: 10px 10px 15px;
    }
}
