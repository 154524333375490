@import "../../config/rtl";

.CardSuggestLink-container {
    width: 300px;
    padding: 5px;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 50px;

    .inner {
        position: relative;
        height: 300px;
        border: 2px solid #eee;
        background-color: #fff;
        box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.01),
            inset 0 0 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 15px 30px;
        overflow: hidden;
        padding: 30px 20px 10px;
        box-sizing: border-box;
        position: relative;
        background-color: dodgerblue;
        background: linear-gradient(
            top,
            dodgerblue,
            dodgerblue,
            lighten(dodgerblue, 10%)
        );

        text-align: center;

        &:hover {
            background: lighten(dodgerblue, 6%);
            cursor: pointer;
        }

        .question {
            margin-top: 30px;
            color: white;
            font-size: 26px;
            margin-bottom: 30px;
        }

        button {
            font-size: 20px;
            border-radius: 10px;
            color: white;
            margin: auto;
            background: palevioletred;
            text-decoration: none;
        }
    }
}

@media (max-width: 1050px) {
    .CardSuggestLink-container {
        width: 100%;
        margin: 10px;
        position: relative;
        top: 0;

        .inner {
            .question {
                margin-top: 40px;
                color: white;
                font-size: 34px;
                line-height: 45px;
                font-weight: 500;
                margin-bottom: 30px;
            }
        }
    }
}
