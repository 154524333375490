.VideoTeleprompter-container {
    display: none;
}

@media (max-width: 1050px) {
    .VideoTeleprompter-container {
        background-color: #222;
        height: 40px;
        display: flex;
        color: #ccc;
        position: relative;

        .inner {
            overflow: hidden;
            margin-top: 10px;

            .scrolling {
                word-wrap: none;
                white-space: nowrap;
                width: 1500px;

                .content {
                    display: inline-block;
                }
            }
        }
    }
}
