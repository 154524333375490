.BlogPost-container {
    background-color: white;
    flex: 1;
    padding-bottom: 300px;

    > .content {
        margin: auto;
        margin-top: 20px;
        width: 728px;
    }

    .saving {
        height: 25px;
        color: brown;
        position: absolute;
        top: 105px;
    }

    .panel {
        position: absolute;
        transform: translateX(-180px) translateY(30px);
    }
}

@media (max-width: 1050px) {
    .BlogPost-container {
        padding-bottom: 50px;

        > .content {
            box-sizing: border-box;
            width: 100vw;
            padding: 20px;
        }

        .saving {
            display: none;
        }

        .panel {
            display: none;
        }
    }
}
