@import "../../config/rtl";

.BigButton-container {
    padding: 5px 12px;
    border-radius: 5px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
    font-size: 16px;
    user-select: none;
    font-family: $font;
    min-width: 80px;
    text-align: center;
    background-color: gray;
    border: 1px solid darken(gray, 5%);
    color: #eee;

    a {
        text-decoration: none;
    }

    &:hover {
        background-color: darken(gray, 5%);
        cursor: pointer;
    }

    &.on {
        background-color: gold;
        border: 1px solid goldenrod;
        color: #333;

        &:hover {
            background-color: darken(gold, 5%);
            cursor: pointer;
        }
    }
}
