.ScreenPanel-container {
    overflow: auto;
    flex: 1;
    color: #f3f3f3;
    position: relative;
    padding-top: 45px;

    .body {
        padding: 0 20px 10px;
    }

    .nav {
        display: none;
    }
}

@media (max-width: 1050px) {
    .ScreenPanel-container {
        background-color: #333;
        padding-top: 10px;

        .IconBar-container,
        .NewBon-container {
            display: none;
        }

        .nav {
            display: block;
            height: 25px;
        }

        .Stack-container:first-child {
            margin-top: 0;
        }
    }
}
