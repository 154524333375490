@import "../../config/rtl";

.FacebookButton-container {
    background-color: #3b5998;
    border: none;
    font-size: 20px;
    padding: 10px 20px;
    color: white;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        background-color: lighten(#3b5998, 10%);
    }

    i {
        font-size: 24px;
        margin-#{$right}: 10px;
    }

    &.small {
        font-size: 14px;
        padding: 6px 10px;
        border-radius: 5px;

        i {
            font-size: 16px;
            margin-#{$right}: 5px;
        }
    }
}
