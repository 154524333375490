.Modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;

    > .modal {
        background-color: white;
        border-radius: 20px;
        animation-duration: 200ms;

        > .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 15px 10px 15px;
            border-bottom: 1px solid #ccc;

            .title {
                flex: 1;
            }
        }

        > .content {
            padding: 10px;
            overflow: auto;
            min-height: 300px;
            max-height: 430px;
        }
    }
}

@media (max-width: 1050px) {
    .Modal-container {
        align-items: flex-end;
        z-index: 999999;
        background-color: transparent;
        pointer-events: none;

        > .modal {
            background-color: #333;
            border-radius: 0;
            color: #eee;
            width: 100%;
            height: 400px;
            border-top: 1px solid #aaa;
            pointer-events: all;

            > .header {
                padding: 0;
                position: relative;
                border: none;

                .close {
                    position: absolute;
                    left: 15px;
                    top: 15px;
                }

                .title {
                    display: none;
                }
            }

            > .content {
                padding: 10px;
                overflow: auto;
                min-height: 300px;
                max-height: 400px;
            }
        }
    }
}
