.Done-container {
    background-color: #ddd;
    width: 22px;
    height: 22px;
    color: #333;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    cursor: pointer;
    text-decoration: none !important;

    &:hover {
        background-color: #ccc;
    }

    &.on {
        background-color: green;

        i {
            color: white;
        }
    }
}
