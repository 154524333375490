.ContentBlank-container {
    .line {
        width: 300px;
        height: 8px;
        margin: 10px;
        background-color: #e3e3e3;

        &.line-1 {
        }

        &.line-2 {
        }

        &.line-3 {
            width: 220px;
        }

        &.line-4 {
        }

        &.line-5 {
            width: 180px;
        }
    }
}
