.PanelModal-container {
    width: 600px;
    padding: 0 30px;
}

@media (max-width: 1050px) {
    .PanelModal-container {
        width: 100%;
        padding: 0 15px 30px;
        box-sizing: border-box;
    }
}
