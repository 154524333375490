@import "../../config/rtl";

.VideoPostLayer-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;

    .lofs {
        flex: 1;
        display: flex;
    }

    .message {
        background-color: #333;
        padding: 50px 0;
        color: #aaa;
        padding: 50px;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        text-align: center;
    }

    .play-pause-next {
        background-color: #222;
        display: block;
        display: flex;
        flex-direction: row;
        align-items: stretch;

        .play {
            padding: 10px 0;
            flex: 1;

            &:active {
                background-color: #111;
            }
        }

        .previous {
            padding: 10px 0;
            width: 70px;
            border-#{$right}: 1px solid #666;

            &:active {
                background-color: #111;
            }
        }

        .next {
            padding: 10px 0;
            width: 70px;
            border-#{$left}: 1px solid #666;

            &:active {
                background-color: #111;
            }
        }
    }
}

@media (max-width: 1050px) {
    .VideoPostLayer-container {
    }
}
