@import "../../config/rtl";

.ScreenFeed-container {
    padding: 20px 20px 50px;

    .content {
        margin: 0 auto;
        max-width: 1050px;

        .debate {
            margin: auto;
            position: relative;
            width: 514px;

            .actions {
                position: absolute;
                left: 10px;
                bottom: 0px;
                z-index: 9;
                display: flex;
                flex-direction: row;
                align-items: center;

                a {
                    background: linear-gradient(
                        90deg,
                        #f4c616 15%,
                        #e89073 40%,
                        #d87093 85%
                    );
                    color: gold;
                    font-family: "heebo";
                    text-decoration: none;
                    padding: 10px;
                    border-radius: 10px;
                    font-size: 22px;
                    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.4),
                        1px -1px 0 rgba(0, 0, 0, 0.4),
                        -1px 1px 0 rgba(0, 0, 0, 0.4),
                        -1px -1px 0 rgba(0, 0, 0, 0.4);
                }
            }

            .lofs {
                color: palevioletred;
                font-size: 15px;
                font-weight: bold;
                padding-#{$right}: 10px;
            }

            .ScreenPost-container {
                .inner {
                    padding: 0 0;
                }
            }

            .TextualPost-container {
                margin-bottom: 10px;
            }
        }
    }
}

@media (max-width: 1050px) {
    .ScreenFeed-container {
        padding: 0;

        .content {
            max-width: 100%;

            .debate {
                width: 100%;

                .actions {
                    bottom: 35px;

                    .lofs {
                        position: absolute;
                        top: 58px;
                        left: 0;
                        right: 0;
                        padding: 0;
                        text-align: center;
                    }
                }
            }
        }
    }
}
