@import "../../config/rtl";

.IconWithLabel-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-#{$right}: 10px;

    span {
        font-size: 12px;
    }
}

@media (max-width: 1050px) {
    .IconWithLabel-container {
        span {
            font-size: 16px;
        }
    }
}
