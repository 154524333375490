@import "../../config/rtl";

.BlogStripeWhite-container {
    .row {
        display: flex;
        flex-direction: row;
        align-items: center;

        .col {
            padding: 20px 0;
            flex: 1;

            &:first-child {
                padding-#{$right}: 1em;
                text-align: $right;
            }

            &:last-child {
                padding-#{$left}: 1em;
            }
        }

        .title {
            font-size: 3em;
            line-height: 1em;
            text-transform: uppercase;
            font-weight: 400;
            text-align: $left;
            align-self: flex-end;
            margin-bottom: 0.4em;
            color: palevioletred;
            max-width: 500px;
            font-family: $font3;
            font-style: italic;
            letter-spacing: 0.02em;

            &:hover {
                color: lighten(palevioletred, 5%);
                text-decoration: none;
            }
        }

        .author {
            margin-top: 7px;
            align-self: flex-end;

            .authorName {
                text-align: $right;
                align-self: flex-end;
                color: palevioletred;
                margin-#{$left}: 5px;
            }
        }

        .subtitle {
            align-self: flex-end;
            font-weight: 400;
            line-height: 1.2em;
            text-transform: none;
            text-align: $right;
            display: block;
            flex-basis: 50%;
            font-size: 1.3em;
        }
    }
}

@media (max-width: 1050px) {
    .BlogStripeWhite-container {
        padding: 0 10px;

        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: auto;

            .col {
                padding: 20px 0;
                flex: 1;

                &:first-child {
                    padding-#{$right}: 1em;
                    text-align: $left;
                }

                &:last-child {
                    padding-#{$left}: 1em;
                }
            }

            .title {
                font-size: 1.7em;
                line-height: 1.1em;
            }

            .author {
                font-size: 0.8em;

                .authorName {
                }
            }

            .subtitle {
                font-size: 1em;
            }
        }
    }
}
