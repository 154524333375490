.BlogQuote-container {
    blockquote {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #eee;
        padding: 10px;
        border-radius: 20px;
        margin: 40px 26px 0;

        span {
            position: relative;
            top: -15px;
            right: -5px;
            font-size: 40px;
        }
    }
}
