@import "../../config/rtl";

.WebBar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    display: flex;
    height: 60px;
    padding: 15px 0;
    flex-direction: row;
    align-items: center;

    .logo {
        font-size: 30px;
        font-weight: bold;
        border: 3px solid #333;
        color: #333;
        float: #{$right};
        border-radius: 3px;
        font-family: "Share Tech Mono", monospace;
    }

    .flex1 {
        flex: 1;
    }

    > .bar {
        margin-#{$right}: 20px;
    }
}

@media (max-width: 1050px) {
    .WebBar-container {
        display: none;
    }
}
