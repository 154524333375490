@import "../../config/rtl";

.SideMenu-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .overlay {
        flex: 1;
    }

    .menu {
        width: 250px;
        background-color: #333;
        padding: 30px 20px;
        box-shadow: -5px 0 5px 2px rgba(0, 0, 0, 0.2);
        position: relative;
        animation-duration: 200ms;

        .actions {
            position: absolute;
            top: 10px;
            #{$right}: 10px;

            .icon {
                float: #{$right};
            }
        }

        .version {
            color: #aaa;
            font-size: 14px;
            position: absolute;
            bottom: 10px;
            #{$right}: 10px;
        }

        ul {
            margin: 20px 0;
            padding: 0;
            border-top: 1px solid #666;

            li {
                margin: 0;
                padding: 0;
                border-bottom: 1px solid #666;

                button {
                    display: block;
                    border: none;
                    background-color: transparent;
                    text-align: #{$left};
                    width: 100%;
                    padding: 10px 10px;
                    color: white;
                    cursor: pointer;
                    font-size: 22px;
                    font-weight: 200;

                    &:hover {
                        background-color: #444;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
