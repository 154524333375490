.ScreenBrowse-container {
    padding: 20px 0;

    .empty {
        font-size: 30px;
        color: #aaa;
        text-align: center;
        font-weight: 200;
        padding: 60px 0;
    }
}

@media (max-width: 1050px) {
    .ScreenBrowse-container {
        padding: 20px 0;

        .container {
            margin: 0 auto;
        }
    }
}
