@import "../../config/rtl";

.BlogStripe-container {
    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 250px;

        .col {
            padding: 20px 0;
            flex: 1;

            &:first-child {
                padding-#{left}: 1em;
            }

            &:last-child {
                padding-#{right}: 1em;
            }
        }

        .title {
            font-size: 1.5em;
            line-height: 1.2;
            text-transform: none;
            font-weight: 600;
            text-align: right;
            align-self: flex-end;
            margin-bottom: 0.4em;
            color: #111;
            max-width: 500px;

            &:hover {
                color: darken(palevioletred, 15%);
                text-decoration: none;
            }
        }

        .authorName {
            text-align: right;
            align-self: flex-end;
        }

        .subtitle {
            align-self: flex-start;
            letter-spacing: 0.02em;
            font-style: italic;
            font-weight: 400;
            letter-spacing: 0.02em;
            line-height: 1;
            font-family: $font3;
            text-transform: uppercase;
            text-align: $left;
            display: block;
            flex-basis: 50%;
            font-size: 3em;
        }
    }
}

@media (max-width: 1050px) {
    .BlogStripe-container {
        .row {
            height: 150px;

            .col {
                padding: 10px 0;

                &:first-child {
                    padding-right: -0.5em;
                }

                &:last-child {
                    padding-left: -0.5em;
                }
            }

            .authorName {
                font-size: 0.7em;
            }

            .title {
                font-size: 1em;
                font-weight: 700;
                max-width: 200px;
                text-align: $right;
            }

            .subtitle {
                font-size: 1.4em;
            }
        }
    }
}
