.Sentences-container {
    user-select: none;

    &.ltr {
        direction: ltr;
    }

    &.rtl {
        direction: rtl;
    }

    .sentence {
        line-height: 18px;
        margin: 0 5px;

        &:first-child {
            margin: 0;
        }

        span {
            border-bottom: 2px solid transparent;
        }

        &.green {
            span {
                border-bottom: 2px solid green;
            }
        }

        &.yellow {
            span {
                border-bottom: 2px solid goldenrod;
            }
        }

        &.purple {
            span {
                border-bottom: 2px solid purple;
            }
        }

        &.blue {
            span {
                border-bottom: 2px solid dodgerblue;
            }
        }

        &.red {
            span {
                border-bottom: 2px solid red;
            }
        }

        &.brown {
            span {
                border-bottom: 2px solid green !important;
                background-color: green !important;
                color: white !important;
            }
        }
    }

    &.editMode {
        .sentence {
            line-height: 18px;
            cursor: pointer;

            &:hover {
                span {
                    background-color: lighten(yellow, 30%);
                }
            }

            &.selected {
                span {
                    background-color: cyan;
                }

                &:hover {
                    span {
                        background-color: darken(cyan, 3%);
                    }
                }
            }
        }
    }

    .break {
        clear: both;
    }
}

body.staging {
    .Sentences-container {
        &.editMode {
            .sentence {
                &:hover {
                    span {
                        color: yellow;
                        background-color: transparent;
                    }
                }

                &.selected {
                    span {
                        background-color: darken(palevioletred, 30%);
                    }

                    &:hover {
                        span {
                            background-color: darken(palevioletred, 30%);
                        }
                    }
                }
            }
        }

        .break {
            clear: both;
        }
    }
}
