@import "../../config/rtl";

.BlogListItem-container {
    margin-top: 12px;
    border-top: 1px solid #ddd;

    .row {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        height: 125px;
    }

    .col {
        &.col-1 {
            width: 220px;

            .image {
                background-size: cover;
                background-position: center center;
                width: 220px;
                height: 125px;
            }
        }

        &.col-2 {
            text-align: $left;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 18px 13px 10px;

            .title {
                color: #333;
                text-decoration: none;
                margin-bottom: 0.5rem;
                font-style: normal;
                font-weight: bold;
                line-height: 1.2;
                text-transform: none;
                font-size: 1.1em;

                &:hover {
                    color: palevioletred;
                }
            }

            .details {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: #5a6a6a;
                font-size: 0.9em;

                .author {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .authorName {
                        padding-#{$left}: 5px;
                        color: palevioletred;
                    }
                }

                .pipe {
                    margin: 0 5px;
                }

                .date {
                }
            }
        }
    }
}

@media (max-width: 1050px) {
    .BlogListItem-container {
        .row {
            height: 85px;
        }

        .col {
            &.col-1 {
                width: 120px;

                .image {
                    background-size: cover;
                    background-position: center center;
                    width: 120px;
                    height: 85px;
                }
            }

            &.col-2 {
                padding: 6px;

                .title {
                    color: #333;
                    font-size: 0.9em;

                    &:hover {
                        color: palevioletred;
                    }
                }

                .details {
                    font-size: 0.75em;
                }
            }
        }
    }
}
