.manager {
    width: 1000px;
    margin: 20px auto;

    > .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .actions {
            display: flex;
            flex-direction: row;
            align-items: center;

            .field {
                margin: 0 10px;
            }

            .icon {
                cursor: pointer;
                font-size: 30px;
                margin: 0 5px;
                color: #aaa;

                &:hover {
                    color: palevioletred;
                }
            }

            a.button {
                margin-#{$left}: 10px;
            }
        }
    }

    td.link {
        text-align: center;
    }
}
