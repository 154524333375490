.BlogBoxes-container {
    background: linear-gradient(90deg, #f4c616 15%, #e89073 40%, #d87093 85%);
    padding-top: 1px;
    padding-left: 1px;
    margin: auto;

    .row {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        padding-bottom: 1px;
    }
}

@media (max-width: 1050px) {
    .BlogBoxes-container {
        width: 100%;

        .row {
            flex-direction: column;
        }
    }
}
