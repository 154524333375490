@import "../../config/rtl";

.VideoPost-container {
    padding: 12px 12px 0;
    background-color: #fff;
    border: 1px solid #dddfe2;
    border-radius: 3px;
    margin-bottom: 20px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 25px;

    .header {
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .root {
        background-color: black;
    }

    .cc {
        padding: 30px 20px;
    }
}

@media (max-width: 1050px) {
    .VideoPost-container {
        margin: 0;
        padding: 0;

        .header {
            padding: 5px 10px 0;
        }

        .cc {
            display: none;
        }

        .lofs {
            flex: 1;
            display: flex;
        }
    }
}
