@import "../../config/rtl";

.CanvasPreview-container {
    background-color: #333;

    .wrapper {
        width: 1200px;
        height: 1200px;
        position: relative;
        margin: auto;

        canvas#background {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 998;
            background-color: #eee;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: black;
            opacity: 0.05;
            z-index: 999;
        }

        canvas#post {
            position: absolute;
            top: 0;
            left: 50%;
            margin-#{$right}: -400px;
            z-index: 1000;
        }
    }
}
