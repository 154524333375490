@import "themes/monokai.scss";
@import "themes/gray.scss";

.color-bk {
    color: $gray_colorBk;
}

.color-font {
    color: $gray_colorFont;
}

.color-1 {
    color: $gray_color1;
}

.color-2 {
    color: $gray_color2;
}

.color-3 {
    color: $gray_color3;
}

.color-4 {
    color: $gray_color4;
}

.color-5 {
    color: $gray_color5;
}

.color-6 {
    color: $gray_color6;
}

.color-bk-bk {
    background-color: $gray_colorBk;
}

.color-font-bk {
    background-color: $gray_colorFont;
}

.color-1-bk {
    background-color: $gray_color1;
}

.color-2-bk {
    background-color: $gray_color2;
}

.color-3-bk {
    background-color: $gray_color3;
}

.color-4-bk {
    background-color: $gray_color4;
}

.color-5-bk {
    background-color: $gray_color5;
}

.color-6-bk {
    background-color: $gray_color6;
}

body.monokai {
    .color-bk {
        color: $monokai_colorBk;
    }

    .color-font {
        color: $monokai_colorFont;
    }

    .color-1 {
        color: $monokai_color1;
    }

    .color-2 {
        color: $monokai_color2;
    }

    .color-3 {
        color: $monokai_color3;
    }

    .color-4 {
        color: $monokai_color4;
    }

    .color-5 {
        color: $monokai_color5;
    }

    .color-6 {
        color: $monokai_color6;
    }

    .color-bk-bk {
        background-color: $monokai_colorBk;
    }

    .color-font-bk {
        background-color: $monokai_colorFont;
    }

    .color-1-bk {
        background-color: $monokai_color1;
    }

    .color-2-bk {
        background-color: $monokai_color2;
    }

    .color-3-bk {
        background-color: $monokai_color3;
    }

    .color-4-bk {
        background-color: $monokai_color4;
    }

    .color-5-bk {
        background-color: $monokai_color5;
    }

    .color-6-bk {
        background-color: $monokai_color6;
    }
}
