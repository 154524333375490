.BlogRoot-container {
    margin-top: 20px;
    margin-right: 20px;
    position: relative;

    .delete {
        position: absolute;
        left: -40px;
        top: 20px;
    }

    .ScreenPost-container {
        .inner {
            padding: 0;
            margin: 0;

            article {
                margin-bottom: 0;
            }
        }
    }
}
