.NewBon-container {
    margin-top: 20px;

    .more {
        height: 0;
        overflow: hidden;

        textarea {
            width: 400px;
            max-height: 200px;
        }

        &.show {
            height: 300px;
        }

        &.rephrase {
            height: 200px;

            textarea {
                height: 100px;
            }
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
