.ScreenVideoPanel-container {
    color: white;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;

    .lof {
        flex: 1;
    }

    .av-buttons {
        height: 50px;
    }
}
