.row {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.wrap {
        flex-wrap: wrap;
    }
}

.col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
