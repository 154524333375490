.ScreenHomeAbout-container {
    padding: 20px 20px 50px;

    .content {
        margin: 0 auto;
        max-width: 1050px;

        p {
            line-height: 22px;
        }

        ul {
            line-height: 22px;

            li {
                margin-top: 10px;
            }
        }
    }
}
