.VideoControls-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 3px;
    pointer-events: none;
}

@media (max-width: 1050px) {
    .VideoControls-container {
        bottom: -6px;
    }
}
