.BlogWikiLink-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: dodgerblue;

    a,
    .Icon-container {
        margin: 0 5px;
    }
}
