@import "../../../config/rtl";

.BlogAuthor-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;

    .avatar {
        width: 48px;
        height: 48px;
        border-radius: 24px;
        background-size: cover;
        background-color: #eee;
    }

    .details {
        margin-#{$left}: 10px;
        line-height: 20px;
        font-size: 16px;

        .title {
            color: rgba(0, 0, 0, 0.84);
            font-weight: 400;
        }

        .dot {
            padding: 0 4px;
        }

        .subtitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: rgba(0, 0, 0, 0.54);
            font-weight: 300;
            font-size: 14px;
        }
    }
}
