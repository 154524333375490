@import "../../config/rtl";

.TextualPost-container {
    padding: 12px 12px 0;
    background-color: #fff;
    border: 1px solid #dddfe2;
    border-radius: 3px;
    margin-bottom: 20px;
    position: relative;

    .reference {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .replies {
        &.sub {
            margin-#{$left}: 45px;
        }
    }
}

body.staging {
    .Post-container {
        background-color: #222;
        border: 1px solid #333;
        border-radius: 15px;
    }
}

@media (max-width: 1050px) {
    .TextualPost-container {
        padding: 20px;
        margin-bottom: 300px;

        .replies {
            &.sub {
                margin-#{$left}: 0;
            }
        }
    }
}
