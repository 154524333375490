.ThankYou-container {
    padding: 20px 20px 50px;
    background-color: dodgerblue;
    background: radial-gradient(dodgerblue, lighten(dodgerblue, 10%));
    flex: 1;
    display: flex;

    .content {
        margin: 0 auto;
        max-width: 1050px;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        font-size: 30px;
        color: white;

        .icon {
            margin-top: 80px;
            animation-delay: 600ms;
        }

        h1 {
            margin-top: 100px;
        }

        h2 {
            margin-top: 10px;
            font-weight: 200;
            font-size: 33px;
            opacity: 0.9;
        }

        h3 {
            font-size: 20px;
            font-weight: 200;
            padding: 0;
            margin: 0;
        }

        a {
            margin: 0 10px;
        }

        .button {
            margin-top: 25px;
            font-size: 22px;
            padding: 5px 50px;
        }

        .take-part {
            font-weight: 200;
            font-size: 20px;
            margin-top: 50px;
        }
    }
}

@media (max-width: 1050px) {
    .ThankYou-container {
        padding: 20px 30px 50px;
        background-color: dodgerblue;
        background: radial-gradient(dodgerblue, lighten(dodgerblue, 10%));
        flex: 1;
        display: flex;

        .content {
            max-width: 100%;

            .icon {
                margin-top: 80px;
            }

            h1 {
                margin-top: 80px;
                font-size: 30px;
            }

            h2 {
                margin-top: 0;
                font-size: 20px;
            }

            a {
                margin-top: 10px;
                display: block;
            }

            .take-part {
                text-align: center;
            }
        }
    }
}
