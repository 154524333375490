@import "../../config/rtl";

.BlogBigBox-container {
    background-color: black;
    width: 740px;
    margin-top: 40px;
    margin-bottom: 30px;

    .header {
        padding: 35px 20px;
        color: white;

        .title {
            color: #fff;
            text-decoration: none;
            margin-bottom: 0.5rem;
            font-style: normal;
            text-transform: none;
            font-family: $font3;
            letter-spacing: 0.008em;
            line-height: 0.9;
            text-transform: uppercase;
            font-size: 3.2em;

            &:hover {
                color: palevioletred;
            }
        }

        .subtitle {
            margin-bottom: 0.5rem;
        }

        .details {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #5a6a6a;
            font-size: 0.9em;

            .author {
                display: flex;
                flex-direction: row;
                align-items: center;

                .authorName {
                    padding-#{$left}: 5px;
                    color: palevioletred;
                }
            }

            .pipe {
                margin: 0 5px;
            }

            .date {
            }
        }
    }

    .image {
        width: 740px;
        height: 415px;
        background-size: cover;
        background-position: center center;
    }
}

@media (max-width: 1050px) {
    .BlogBigBox-container {
        background-color: black;
        width: 100%;

        .image {
            width: 100%;
            max-height: 345px;
        }

        .header {
            padding: 35px 20px;
            color: white;

            .title {
                font-size: 2em;
            }
        }
    }
}
