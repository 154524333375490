.WebBarMenu-container {
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        li {
            padding: 0;
            margin: 0;

            &.strong {
                font-weight: 500;
                color: brown;
            }

            a {
                padding: 0 10px;
                color: #333;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 1000px) {
    .WebBarMenu-container {
        width: 250px;

        ul {
            flex-direction: column;
            align-items: stretch;

            li {
                a {
                    border: 1px solid gray;
                    color: #333;
                    padding: 10px 20px;
                    display: block;
                    margin: 5px;
                    direction: rtl;
                }
            }
        }
    }
}
