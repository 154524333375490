@import "../../config/rtl";

.UserBar-container {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    padding: 5px 10px;
    color: #f3f3f3;
    height: 33px;
    background-color: #333;
    z-index: 9999;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    direction: ltr;

    .hamburger {
        float: #{$left};
    }

    .spacer {
        flex: 1;
        display: none;
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex: 1;

        .index {
            padding: 3px 10px;
            margin: 0 8px;
            min-width: 25px;
            text-align: center;
            border: 1px solid #666;
            border-radius: 10px;
        }
    }

    .back {
        margin: 0 10px;
    }

    .edit-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 10px;

        .done {
            margin: 0 15px 0 10px;
        }
    }

    .logo {
        font-size: 23px;
        font-weight: bold;
        border: 3px solid #333;
        color: #fff;
        float: #{$right};
        border-radius: 3px;
        margin-left: 9px;
        font-family: "Share Tech Mono", monospace;
    }

    .logo-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
    }

    .user-container {
        margin-#{$left}: 10px;
        position: relative;

        .inner {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 3px 10px;
            border-radius: 5px;

            .name {
                padding: 0 7px;
            }

            .photo {
                width: 30px;
                height: 30px;
                border-radius: 15px;
                background-size: cover;
                background-color: #999;
            }

            &:hover,
            &.selected {
                background-color: #444;
                cursor: pointer;
            }
        }
    }

    .menu {
        position: absolute;
        top: 28px;
        right: -3px;
        width: 150px;
        user-select: none;

        ul {
            padding: 0;
            margin: 0;
            overflow: hidden;
            border-radius: 10px;
            background-color: #333;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
            border: 1px solid #666;

            li {
                margin: 0;
                border-bottom: 1px solid #666;

                a {
                    display: block;
                    color: #eee;
                    padding: 8px 10px;
                    text-decoration: none;
                    font-size: 14px;
                }

                &.hide {
                    display: none;
                }

                &:hover {
                    background-color: #444;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    .offline {
        bottom: 10px;
        left: 10px;
        border-radius: 6px;
        margin: 0 10px;
        color: #444;
        padding: 3px 10px;
        background-color: gold;
    }

    .site {
        bottom: 10px;
        left: 10px;
        border-radius: 6px;
        margin: 0 10px;
        background-color: #444;
        padding: 3px 10px;
        color: gold;

        span {
            color: #999;
            margin-left: 6px;
            font-size: 12px;
        }

        &.staging {
            color: white;
            background-color: brown;

            span {
                color: #eee;
            }
        }
    }
}

body[dir="rtl"] {
    .UserBar-container {
        text-align: right;

        .actions {
            justify-content: flex-end;
        }

        .hamburger {
            order: 5;
        }
    }
}

@media (max-width: 1050px) {
    .UserBar-container {
        .actions {
            display: none;
        }

        .spacer {
            display: block;
            flex: 1;
        }
    }
}
