.ScreenPost-container {
    > .inner {
        margin: auto;
        width: 514px;
        padding: 20px;

        .overlay {
            display: none;
        }
    }

    &.video {
        > .inner {
            padding: 0;
        }
    }
}

@media (max-width: 1050px) {
    .ScreenPost-container {
        display: flex;
        flex: 1;

        > .inner {
            margin: 0;
            padding: 0 5px;
            box-sizing: border-box;
            width: 100%;
            display: flex;
            flex: 1;

            .overlay {
                position: fixed;
                left: 0;
                bottom: 0;
                right: 0;
                height: 500px;
                background-color: transparent;
                z-index: 9999;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                .widget-panel {
                    background-color: #333;
                    color: #fff;
                    height: 500px;
                }

                .widget-wiki {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #444;
                    display: none;
                }
            }
        }

        &.video {
            > .inner {
                padding: 0;
            }
        }
    }
}
