.Isomer-container {
    position: relative;

    .item {
        padding: 5px;
        position: absolute;
    }

    .pointer {
        width: 5px;
        height: 5px;
        border-radius: 2px;
        background-color: green;
        position: absolute;
    }
}
