.PanelVideo-container {
    background-color: #333;
    padding: 30px;
    flex: 1;
    display: block;
    overflow: auto;
    margin-bottom: 10px;

    .inner {
        height: 0;

        .preview {
            margin-top: -10px;

            .time {
                color: orange;
                font-weight: 500;
                margin-bottom: 5px;
            }

            .comment {
                font-size: 20px;
                max-width: 250px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                color: white;
            }
        }

        .stack {
            margin-top: -5px;
            zoom: 0.9;
        }
    }
}

@media (max-width: 1050px) {
    .PanelVideo-container {
        margin-bottom: 0;
    }
}
