.Project-container {
    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .body {
        display: flex;
        flex-direction: row;

        .Content-container {
            line-height: 20px;
            max-width: 800px;
        }
    }
}

@media (max-width: 1000px) {
    .Project-container {
        .header {
            flex-direction: row;
        }

        .body {
            flex-direction: column;

            .Content-container {
                line-height: 22px;
            }
        }
    }
}
