.BlogElement-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 30px;
        width: 80px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 150ms ease-out;
    }

    .component {
        width: 600px;
    }

    &:hover {
        .actions {
            opacity: 1;
            pointer-events: all;
        }
    }

    &.locked {
        .actions {
            opacity: 0;
            pointer-events: none;
        }
    }

    &.active {
        .actions {
            opacity: 1;
            pointer-events: all;
        }
    }

    &.header,
    &.author {
        .actions {
            opacity: 0;
            pointer-events: none;
        }
    }

    .panel {
        position: absolute;
    }
}
