.CommentRoot-container {
    .popoi {
        color: #fff;
        text-shadow: -1px 0 0 #aaa;
        font-size: 40px;
        position: absolute;
        left: -26px;
        top: 50%;
        margin-top: -30px;
        display: none;

        &.show {
            display: block;
        }
    }

    .post-body {
        margin: 6px 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.38;
        color: #1d2129;
        padding-bottom: 10px;
        position: relative;
    }

    .video {
        margin: 20px 0 5px;
        color: dodgerblue;
    }

    .image {
        margin: 20px 0 5px;
        animation-duration: 150ms;

        img {
            width: 100%;
        }
    }

    .stats {
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display: flex;
        flex-direction: row;
        padding: 3px 0;
        margin-bottom: 15px;
    }
}

body.staging {
    .CommentRoot-container {
        .post-body {
            color: #f3f3f3;
        }

        .video {
            color: palevioletred;
        }

        .stats {
            border-top: 1px solid #222;
            border-bottom: 1px solid #222;
        }
    }
}

@media (max-width: 1050px) {
    .CommentRoot-container {
        .popoi {
            &.show {
                display: none !important;
            }
        }

        .post-body {
            margin-top: 15px;
            font-size: 16px;
            line-height: 1.38;
        }
    }
}
